<template>
  <header class="sticky-top navbar-expand-lg bg-primary">
    <nav class="navbar navbar-dark container-lg size-md">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="../assets/favicon.png" width="20">  
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">JejuDo.kn</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">

          <ul class="navbar-nav flex-grow-1 pe-3">
            <li class="nav-item" v-for="(a, i) in menu" :key="a">
              <router-link class="nav-link" :to="link[i]">{{  menu[i] }}</router-link>
            </li>
          </ul>

          <ul class="navbar-nav lh-1 flex-row flex-wrap ms-md-auto">
            <li class="nav-item col-6 col-lg-auto">
              <a class="nav-link py-2 px-0 px-lg-2" rel="noopener" data-bs-toggle="tooltip" data-bs-placement="left" title="지금 제주 날씨는">
                <span class="me-2">
                    <i class="bi" :class="icons"></i>
                </span>
                <span>{{ weather.main?.temp }}°</span>
              </a>
            </li>
            <li class="nav-item py-2 py-lg-1 col-12 col-lg-auto">
              <div class="vr d-none d-lg-flex h-100 mx-lg-2 text-white"></div>
              <hr class="d-lg-none my-2 text-white-50">
            </li>
            <li class="nav-item col-6 col-lg-auto">
              <a class="nav-link py-2 px-0 px-lg-2" href="https://github.com/ddokn" target="_blank" rel="noopener">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="navbar-nav-svg" viewBox="0 0 512 499.36" role="img"><title>GitHub</title><path fill="currentColor" fill-rule="evenodd" d="M256 0C114.64 0 0 114.61 0 256c0 113.09 73.34 209 175.08 242.9 12.8 2.35 17.47-5.56 17.47-12.34 0-6.08-.22-22.18-.35-43.54-71.2 15.49-86.2-34.34-86.2-34.34-11.64-29.57-28.42-37.45-28.42-37.45-23.27-15.84 1.73-15.55 1.73-15.55 25.69 1.81 39.21 26.38 39.21 26.38 22.84 39.12 59.92 27.82 74.5 21.27 2.33-16.54 8.94-27.82 16.25-34.22-56.84-6.43-116.6-28.43-116.6-126.49 0-27.95 10-50.8 26.35-68.69-2.63-6.48-11.42-32.5 2.51-67.75 0 0 21.49-6.88 70.4 26.24a242.65 242.65 0 0 1 128.18 0c48.87-33.13 70.33-26.24 70.33-26.24 14 35.25 5.18 61.27 2.55 67.75 16.41 17.9 26.31 40.75 26.31 68.69 0 98.35-59.85 120-116.88 126.32 9.19 7.9 17.38 23.53 17.38 47.41 0 34.22-.31 61.83-.31 70.23 0 6.85 4.61 14.81 17.6 12.31C438.72 464.97 512 369.08 512 256.02 512 114.62 397.37 0 256 0z"></path></svg>
                <small class="d-lg-none ms-2">GitHub</small>
              </a>
            </li>
            <li class="nav-item col-6 col-lg-auto">
              <a class="nav-link py-2 px-0 px-lg-2" href="https://www.instagram.com/kim.dokn/" target="_blank" rel="noopener">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                  </svg>
                <small class="d-lg-none ms-2">Instagram</small>
              </a>
            </li>
            <!-- <li class="nav-item col-6 col-lg-auto">
              <router-link to="/login" class="nav-link py-2 px-0 px-lg-2" href="https://www.instagram.com/kim.dokn/" rel="noopener" style="font-size: 18px;" data-bs-toggle="tooltip" data-bs-placement="bottom" title="로그인">
                <i class="bi bi-person-fill-add"></i>
             </router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>

</template>

<script>
export default {
    name: 'NavBar',
    data(){
        return {
          event: ''
        }
    },
    props: {
        menu: Number,
        weather: Array,
        apiLoad: Boolean,
        icons: Array,
        link: Array
    },
    mounted(){
      this.removeFake();
    },
    methods: {
      removeFake(){
        const myOffcanvas = document.getElementById('offcanvasDarkNavbar')
        myOffcanvas.addEventListener('show.bs.offcanvas', event => {
          this.event = event;
          let fade = document.getElementsByClassName('offcanvas-backdrop fade show');
          for( let i = 0; i < fade.length; i++ ) {
            while( fade.length > 0 ) {
              fade[i].remove()
            }
          }        
        }),
        myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
          this.event = event;
          document.body.style = '';
        })
      }
    },
}
</script>

<style lang="scss">
.navbar {
    height: 62px;
    &-toggler {
        &:focus {
            box-shadow: none;
        }
    }
    .navbar-nav {
        align-items: center;
    }
}
@media (max-width: 991px) {
  .navbar-toggler {
    &:focus {
      box-shadow: none !important;
    }
  }
  .offcanvas {
    max-width: 80% !important;
    .navbar-nav {
      padding-right: 0 !important;
      margin-top: 1rem !important;
      margin-bottom: 3rem !important;
      .nav-item {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
<template>
  <WaitPage/>
</template>

<script>
import WaitPage from '@/components/WaitPage.vue'
export default {
  components: { WaitPage },
    name: 'RecordList'
}
</script>

<style>

</style>
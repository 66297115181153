import { createStore } from 'vuex';

const store = createStore ({
  state: { // 변수들의 집합
  },
  getters: { // state의 변수들을 get 호출
  },
  mutations: { // 변수들을 조작하는 함수들
  },
  actions: { // 비동기 처리를 하는 함수들
  },
});

export default store;
<template>
  <footer class="bg-dark">
      <section>
        <div class="container-lg px-4 py-4">
          <div class="copyright text-center fs-sm">made by ddokn</div>
          <div class="copyright text-center fw-bold mt-1">© 2023 JejuDo.kn</div>
        </div>
      </section>
    </footer>
</template>

<script>
export default {
    name: 'FooterBar',
}
</script>

<style>

</style>